// src/pages/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Header.module.css';

function Header() {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>¡BIENVENIDOS A BORDO!</h1>
      <nav className={styles.nav}>
        <Link to="/" className={styles.link}>¡BIENVENIDOS!</Link>
        <Link to="/plan-de-vuelo" className={styles.link}>PLAN DE VUELO</Link>
        <Link to="/equipaje" className={styles.link}>EQUIPAJE</Link>
        <Link to="/check-in" className={styles.link}>CHECK-IN</Link>
        <Link to="/contact" className={styles.link}>CONTACTO Y PREGUNTAS</Link>
      </nav>
    </header>
  );
}

export default Header;
