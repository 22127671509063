// src/pages/Contact.js
import React from 'react';
import topImage from '../images/contact.png';
import styles from '../styles/Contact.module.css';

function Contact() {
  return (
    <section className={styles.contactSection}>
      <div className={styles.topImageContainer}>
        <img src={topImage} alt="Contact header" className={styles.topImage} />
      </div>
      <div className={styles.faqSection}>
        <h3 className={styles.faqTitle}>Preguntas Frecuentes:</h3>
        <div className={styles.faqItem}>
          <strong>¿Hay parqueadero?</strong>
          <p>¡¡¡Claro que Sí!!! ¡Tenemos el Hangar listo para ti, asegúrate de pedir conductor elegido!</p>
        </div>
        <div className={styles.faqItem}>
          <strong>¿Soy Vegetariano, hay algún problema?</strong>
          <p>¡Para nada! ¡Solo confírmanos que eres Vegetariano al momento de realizar tu check in!</p>
        </div>
        <div className={styles.faqItem}>
          <strong>¿Puedo ir Acompañado?</strong>
          <p>Nos encantaría, pero El boleto es personal e intransferible.</p>
        </div>
        <div className={styles.faqItem}>
          <strong>¿Como debo ir Vestido?</strong>
          <p>Asegúrate de abordar con vestido de fiesta para las mujeres y Traje formal con corbata para los hombres. Están restringidos artículos peligrosos como Corbatines.</p>
        </div>
        <div className={styles.faqItem}>
          <strong>¿Puedo ir con mis Hijos?</strong>
          <p>Pronosticamos mucha turbulencia en el vuelo, recomendamos dejarlos sanos y salvos en Casa.</p>
        </div>
        <div className={styles.faqItem}>
          <strong>¿Debo llevar Algún Presente en Especifico?</strong>
          <p>Asegúrate de empacar toda tu buena energía y ganas de acompañarnos en este día tan especial, auguramos una Tormenta de sobres ese día, ya que no tenemos lista de regalos.</p>
        </div>
        <div className={styles.faqItem}>
          <strong>¿A qué hora debo llegar?</strong>
          <p>Es importante que seas puntual, te esperamos a las 03:00pm.</p>
        </div>
      </div>


      <div className={styles.contactDetails}>
        <h2 className={styles.contactTitle}>Información de contacto</h2>
        <p className={styles.contactText}>No duden en contactarnos para cualquier información adicional</p>
        <h3 className={styles.contactSubTitle}>Teléfonos:</h3>
        <p className={styles.contactPhone}>(+57) 314 279-3276</p>
        <p className={styles.contactPhone}>(+57) 318 791-1937</p>
      </div>

      <div className={styles.specialMessage}>
        <p>Nos queremos como se quieren los patos: <strong>patodalavida</strong>. ¡Gracias por acompañarnos en este día tan especial!</p>
        <div className={styles.gifContainer}>
          <iframe
            src="https://tenor.com/embed/6689560339757652623"
            className={styles.duckGif}
            allowFullScreen
            title="Duck GIF"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Contact;