// src/pages/Equipaje.js
import React from 'react';
import styles from '../styles/Equipaje.module.css';
import image1 from '../images/Trajes.png';
import image2 from '../images/Vestidos.png';

function Equipaje() {
  return (
    <section id="equipaje" className={styles.equipajeSection}>
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          <h2 className={styles.imageTitle2}>Para ellas</h2>
          <img src={image2} alt="Equipaje Image 2" className={styles.equipajeImage} />
          <ul className={styles.descriptionList}>
            <li>Vestido de Fiesta o de Coctel (preferiblemente Largo) Para que Luzcas Preciosa!</li>
            <li>Con los Accesorios y/o Joyería que Quieras, Queremos verte Reluciente</li>
            <li>Zapatos de Tacon, con los que te sientas Cómoda y arrases en la pista!</li>
            <li>El frío puede estar presente, Recomendamos llevar algo que te abrigue!</li>
          </ul>
        </div>
        <div className={styles.imageWrapper}>
          <h2 className={styles.imageTitle}>Para ellos</h2>
          <img src={image1} alt="Equipaje Image 1" className={styles.equipajeImage1} />
          <ul className={styles.descriptionList}>
            <li>Traje Formal - Esperamos que estés muy Elegante!</li>
            <li>Camisa manga Larga, Con todo tu Estilo!</li>
            <li>No olvides tu Corbata, en el color que prefieras!</li>
            <li>Zapatos Formales - Hay que hacer Match con tu super Pinta</li>
          </ul>
        </div>

      </div>
    </section>
  );
}

export default Equipaje;