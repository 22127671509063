import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './pages/Header';
import Welcome from './pages/Welcome';
import DateLocation from './pages/DateLocation';
import CheckIn from './pages/CheckIn';
import Equipaje from './pages/Equipaje';
import Contact from './pages/Contact';

function App() {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const targetDate = new Date('2024-12-07T15:00:00-05:00'); // Dec 7, 2024, 3:00 PM UTC-5

    const updateCountdown = () => {
      const now = new Date();
      const timeDifference = targetDate - now;

      if (timeDifference <= 0) {
        setCountdown('¡Hemos despegado!');
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <div className="App" style={{ backgroundColor: '#003459', color: '#ffffff' }}>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Navigate to="/bienvenidos" />} />
            <Route path="/bienvenidos" element={<Welcome />} />
            <Route path="/plan-de-vuelo" element={<DateLocation />} />
            <Route path="/check-in" element={<CheckIn />} />
            <Route path="/equipaje" element={<Equipaje />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <footer style={{ backgroundColor: '#003459', color: '#ffffff', padding: '10px', textAlign: 'center' }}>
          <div className="countdownContainer">
            <div className="countdownTitle">Cuenta regresiva para el despegue</div>
            <div className="countdown">{countdown}</div>
          </div>
          <p>&copy; 2024 Juanda & Pipe. Derechos reservados.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
