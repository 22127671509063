// src/pages/CheckIn.js
import React from 'react';
import styles from '../styles/CheckIn.module.css';
import checkInImage from '../images/img1.jpg'; // Update path if necessary

function CheckIn() {
  return (
    <section id="check-in" className={styles.checkInSection}>
      <div className={styles.checkInTextContainer}>
        <h2 className={styles.checkInTitle}>¡Hola, amigos y familia! 🌍✈️</h2>
        <p className={styles.checkInText}>
          Estamos a punto de embarcarnos en una aventura increíble, y queremos que ustedes sean parte de este emocionante viaje. Para ayudarnos a planificar todo con la precisión de un vuelo perfecto, necesitamos saber si podrán acompañarnos en esta celebración especial.
          <br /><br />
          Hagan clic en el enlace a continuación para confirmar su asistencia y asegurar su lugar en este vuelo hacia momentos inolvidables. 🌟
          <div className={styles.checkInLinkContainer}>
            <a href="https://forms.gle/e73ngPkJHLP9Epte9" className={styles.checkInLink} target="_blank" rel="noopener noreferrer">¡Confirmar mi asistencia!</a>
          </div>
          Pronosticamos un día lleno de alegría, risas y, por supuesto, ¡diversión! Vengan con su mejor actitud y prepárense para disfrutar de una experiencia que seguro recordaremos por siempre. 
          <span className={styles.boldHugeText}> ¡Recuerda no volar con niños y/o bebés a bordo!</span>
          ¡Nos encantaría contar con su presencia para que juntos hagamos de este evento algo verdaderamente memorable!
          <br /><br />
          Con mucho cariño,
          <br />
          Juanda y Pipe
        </p>
      </div>
      <div className={styles.checkInImageContainer}>
        <img src={checkInImage} alt="Check In" className={styles.checkInImage} />
      </div>
    </section>
  );
}

export default CheckIn;
