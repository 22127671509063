// src/pages/DateLocation.js
import React from 'react';
import styles from '../styles/DateLocation.module.css';
import howToGetImage from '../images/mapa.jpg'; // Update with the correct path
import topImage from '../images/Plan de Vuelo.png';

function DateLocation() {
  return (
    <section id="date-location" className={styles.sectionDateLocation}>
      <div className={styles.topImageContainer}>
        <img src={topImage} alt="Top decoration" className={styles.topImage} />
      </div>
      <div className={styles.dateLocationContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.dateLocationTitle}>Pase de abordaje</h2>
          <div className={styles.dateLocationDetails}>
            <p><span className={styles.contentTextLabel}>Fecha:</span><span className={styles.descriptionTextLabel}> 7 de Diciembre de 2024</span></p>
            <p><span className={styles.contentTextLabel}>Hora de abordaje:</span><span className={styles.descriptionTextLabel}> 3:00 PM</span></p>
          </div>
          <p className={styles.dateLocationSala}><span className={styles.contentTextLabel}>Sala:</span><span className={styles.descriptionTextLabel}> Hacienda Montecano. Subachoque, Cundinamarca</span></p>
        </div>
        <div className={styles.imageContainer}>
          <h3 className={styles.howToGetTitle}>¿Cómo llegar?</h3>
          <img src={howToGetImage} alt="Cómo llegar" className={styles.howToGetImage} />
        </div>
      </div>
      <div className={styles.mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.220452457109!2d-74.19677152445097!3d4.902733527196419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e407fb6f825f57b%3A0xe74f3b433c5c1328!2sHacienda%20Montecano%20%7C%20Esencia%20de%20Bodas!5e0!3m2!1ses!2sco!4v1721574178739!5m2!1ses!2sco"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
}

export default DateLocation;