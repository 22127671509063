import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styles from '../styles/Welcome.module.css';
import welcomeImage0 from '../images/img0.jpg';
import welcomeImage1 from '../images/img10.jpg';
import welcomeImage2 from '../images/img2.jpg';
import welcomeImage3 from '../images/img9.jpg';
import welcomeImage5 from '../images/img5.jpg';
import welcomeImage6 from '../images/img6.jpg';
import welcomeImage8 from '../images/img11.jpg';

function Welcome() {
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const images = [
            welcomeImage0,
            welcomeImage1,
            welcomeImage2,
            welcomeImage3,
            welcomeImage5,
            welcomeImage6,
            welcomeImage8
        ];

        let loadedImages = 0;

        images.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImages += 1;
                if (loadedImages === images.length) {
                    setImagesLoaded(true);
                }
            };
        });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <section id="welcome" className={styles.sectionWelcome}>
            <div className={styles.contentContainer}>
                {imagesLoaded ? (
                    <div className={styles.sliderContainer}>
                        <Slider {...settings}>
                            <div>
                                <img src={welcomeImage0} alt="Slide 0" className={styles.sliderImage} />
                            </div>
                            <div>
                                <img src={welcomeImage1} alt="Slide 1" className={styles.sliderImage} />
                            </div>
                            <div>
                                <img src={welcomeImage2} alt="Slide 2" className={styles.sliderImage} />
                            </div>
                            <div>
                                <img src={welcomeImage3} alt="Slide 3" className={styles.sliderImage} />
                            </div>
                            <div>
                                <img src={welcomeImage5} alt="Slide 5" className={styles.sliderImage} />
                            </div>
                            <div>
                                <img src={welcomeImage6} alt="Slide 6" className={styles.sliderImage} />
                            </div>
                            <div>
                                <img src={welcomeImage8} alt="Slide 8" className={styles.sliderImage} />
                            </div>
                        </Slider>
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
                <div className={styles.textContainer}>
                    <p className={styles.welcomeText}>
                        Queridos amigos y familiares,
                        <br /><br />
                        Estamos ansiosos por compartir con ustedes este momento tan especial en nuestras vidas.
                        <br /><br />
                        Prepárense para una celebración llena de risas, baile y, sobre todo, mucho amor.
                        Este día no sería lo mismo sin la presencia de cada uno de ustedes, que han sido
                        parte fundamental de nuestra historia.
                        <br /><br />
                        Así que vengan con sus mejores energías, sus sonrisas más brillantes y sus zapatos de baile.
                        ¡Vamos a crear recuerdos inolvidables juntos!
                        <br /><br />
                        Con todo nuestro cariño,
                        <br />
                        Juanda y Pipe
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Welcome;
